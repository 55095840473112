import React from 'react';
import './Sticker.css';

function Sticker(props) {
  const { priceCase, data } = props;
  let sticker;
  const template = data.template;
  template === 'c2c' ? sticker = 'c2c' : template === 'special-event' ? sticker = 'special-event' : sticker = priceCase;
  let qualityType = data.qualityType;
  if (qualityType === 'Très Bon Etat') { qualityType = 'Très Bon état' };
  if (qualityType === 'Bon Etat') { qualityType = 'Bon état' };

  return (
    <React.Fragment>
      { sticker === 'c2c' &&
        <div className="flux-promo-item-sticker-c2c purple-c2c flex justify-center items-center">
          <img className="flux-promo-icon pr2" src="https://images.fr.shopping.rakuten.com/visuels/2019-09-20_newfluxmerch/images/icon_c2c.svg " alt="revente"/>
          <p className="flux-promo-item-sticker-text b">{qualityType.toUpperCase()}</p>
        </div>
      }
      { sticker === 'special-event' &&
        <div className="flux-promo-item-sticker bg-red-rkt white center flex justify-center items-center">
          <img className="flux-promo-icon icon-sticker skew10 pr2" src="https://images.fr.shopping.rakuten.com/visuels/2019-09-20_newfluxmerch/images/icon_sticker.svg" alt="éclair à saisir"/>
          <p className="flux-promo-item-sticker-text skew10 b">VENTE FLASH</p>
        </div>
      }
      { sticker === 'isCoupon' &&  
        <div className="flux-promo-item-sticker bg-red-rkt white center flex justify-center items-center">
          <img className="flux-promo-icon skew10 pr2" src="https://images.fr.shopping.rakuten.com/visuels/2019-09-20_newfluxmerch/images/icon_timer.svg" alt="durée limitée"/>
          <p className="flux-promo-item-sticker-text skew10 b">Durée limitée</p>
        </div>
      }
      { sticker === 'is4x' &&  
        <div className="flux-promo-item-sticker bg-red-rkt white center flex justify-center items-center">
          <img className="flux-promo-icon icon-sticker skew10 pr2" src="https://images.fr.shopping.rakuten.com/visuels/2019-09-20_newfluxmerch/images/icon_sticker.svg" alt="éclair à saisir"/>
          <p className="flux-promo-item-sticker-text skew10 b">BON PLAN !</p>
        </div>
      }
      { sticker === 'isPromo' &&  
        <div className="flux-promo-item-sticker bg-red-rkt white center flex justify-center items-center">
          <p className="flux-promo-item-sticker-text skew10 b">SUPER PROMO</p>
        </div>
      }
      { sticker === 'noPromo' &&  
        <div className="flux-promo-item-sticker bg-red-rkt white center flex justify-center items-center">
          <p className="flux-promo-item-sticker-text skew10 b">COUP DE </p>
          <img className="flux-promo-icon icon-heart skew10 pr2" src="https://images.fr.shopping.rakuten.com/visuels/2019-09-20_newfluxmerch/images/icon_coeur.svg" alt="coup de coeur"/>
        </div>
      }
    </React.Fragment>
  );
};

export default Sticker;